@keyframes mymove {
  from {
    left: 0px;
  }

  to {
    left: 200px;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-slide {
  height: 100vh !important;
}

.swiper-slide img {
  height: 100%;
  z-index: 1;
  position: fixed;
  width: 100%;
}

@keyframes deneme {}

.swiper-slide h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 5;
  position: relative;
  font-size: 81px;
  width: 100%;
  margin: 0px;
  text-align: center;
}

.swiper-slide h3 a {
  text-decoration: none;
  color: black;
}

.swiper-slide h3 a .ss {
  font-size: 60px;
}

.banner {
  display: block;
}

.mobileBanner {
  display: none;
}

.patternsDesktop {
  display: block;
}

.patternsMobile {
  display: none;
}

.swiper-slide:nth-child(2) h3 {
  animation: backInDown;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

.swiper-slide:nth-child(3) h3 {
  animation: backInDown;
  animation-duration: 3s;
  animation-iteration-count: 1;
}


.menuButton {
  z-index: 2;
  display: inline-grid;
  position: absolute;
  right: 0px;
  margin: 20px;
  cursor: pointer;
}

.logo img {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 10%;
  padding-left: 20px;
}

.menuOpen {
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: black;
  position: absolute;
}

.menuButton span {
  height: 6px;
  width: 30px;
  border-top: 3px solid;
  position: relative;
}

.menuOpen span {
  color: white;
  z-index: 7;
}

div.opened {
  height: 100vh;
  width: 100%;
  background-color: black;
}

.sliderImages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100vh;
}

.hiddenMenu {
  display: none;
}

.active .hiddenMenu {
  display: flex;
  margin-left: 20%;
  align-items: center;
  height: 100vh;
}

.hiddenMenu ul {
  color: white;
}

.hiddenMenu li {
  list-style: none;
  padding-bottom: 10px;
  font-size: 32px;
  animation: fadeInLeft;
  animation-duration: 1s;
}

.hiddenMenu li a {
  text-decoration: none;
  color: #FFFFFF;
}

.hiddenMenu li:hover {
  transition-duration: 2s;
  background-color: #FFFFFF;

}

.hiddenMenu li:hover a {
  color: #000;
}


.not {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.active {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100vh;
  z-index: 3;
  left: 0;
  transition: 0.50s;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  right: 0;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham3 .top {
  stroke-dasharray: 40 130;
}

.ham3 .middle {
  stroke-dasharray: 40 140;
}

.ham3 .bottom {
  stroke-dasharray: 40 205;
}

.active .ham3 .top {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
  stroke: white;

}

.active .ham3 .middle {
  stroke-dashoffset: -102px;
  stroke: white;

}

.active .ham3 .bottom {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
  stroke: white;
}


/* Banner Mobil */
@media screen and (max-width: 700px) {
  .banner {
    display: none;
  }

  .mobileBanner {
    display: block;
  }

  .hiddenMenu li {
    font-size: 24px;
  }

  .patternsDesktop {
    display: none;
  }

  .patternsMobile {
    display: block;
  }

  .patternsMobile .patterns svg text {
    font-size: 25px;
  }
  .swiper-slide h3 {
    font-size: 30px;
  }
}










/* Header  */
.headerClass {
  display: flex;
  height: 75px;
}

.headerLogo {
  height: 75px;
  width: 25%;
}

.headerLogo img {
  width: 45%;
  padding-left: 40px;
}

.headerMenu {
  display: flex;
  width: 100%;
  list-style: none;
  height: 100%;
  align-items: center;
  margin: 0px;
  justify-content: right;
}

.headerMenu li {
  padding-right: 20px;
}

.headerMenu li a{
  color: #67686A;
}

.headerMenu li a:focus {
  color: #67686A;
}

.headerMenu li a:hover {
  color: #67686A;
}

.servicesMenu {
  display: none;
}

.services:hover .servicesMenu {
  background-color: #FFFFFF;
  display: block;
  position: absolute;
  width: 35%;
  margin-top: 28px;
  list-style: none;
  margin-left: 0px;
  padding: 0px;
}

.tabletMenu {
  display: none;
}




/* Header Mobil */
@media screen and (max-width: 1200px) {
  .headerLogo img {
    width: 67%;
  }
  .worksPage .worksPageDescriptions h2{
    font-size: 50px;
  }
}

@media screen and (max-width: 1023px) {
  .headerMenu {
    display: none;
  }

  .tabletMenu {
    display: block;
  }

  .headerLogo img {
    width: 70%;
  }
}

@media screen and (max-width: 700px) {
  .headerLogo {
    height: 75px;
    width: 50%;
  }

  .headerLogo img {
    width: 75%;
    padding-left: 10px;
  }

  .active .hiddenMenu {
    margin-left: 0;
  }

  .logo img {
    width: 45%;
  }
}


ul li a {
  text-decoration: none;
}





/* Footer  */

.footer {
  background: rgb(0,68,146);
  background: linear-gradient(180deg, rgba(0,68,146,1) 0%, rgba(0,130,175,1) 100%);
  min-height: 100px;
  margin-top: 100px;
  display: flex;
}

.footer .ant-row {
  height: 100%;
}

.footer .addressArea {
  align-self: center;
  width: 50%;
  padding-left: 100px;
}

.footer .socialMediaButtons {
  width: 50%;
  padding-right: 150px;
  display: flex;
  justify-content: flex-end;
}

.address {
  color: #FFFFFF;
}

.mailAddress a {
  color: #FFFFFF;
  text-decoration: none;
}

.address a {
  color: #FFFFFF;
  text-decoration: none;
}

.socialMediaButtons ul {
  display: flex;
  list-style: none;
  height: 100%;
  align-items: center;
  margin: 0px;
}

.socialMediaButtons ul li a {
  color: #FFFFFF;
  padding-right: 10px;
}

.socialMediaButtons li:nth-child(1):hover a {
  color: #E1306C;
}

.socialMediaButtons li:nth-child(2):hover a {
  color: #1666C5;
}

.socialMediaButtons li:nth-child(3):hover a {
  color: #1877F2;
}

.socialMediaButtons li:nth-child(4):hover a {
  color: #EA4335;
}

.socialMediaButtons li:nth-child(5):hover a {
  color: #EA4335;
}

.socialMediaButtons a svg {
  height: 25px;
  width: 25px;
}

/* Footer  Mobil*/

@media screen and (max-width: 700px) {
  .footer {
    display: block;
  }

  .footer .addressArea {
    padding: 20px;
    text-align: center;
    width: auto;
  }

  .footer .socialMediaButtons {
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
    padding-right: 0px;
  }
}








/* Detail Pages*/

.detailPageİmages img {
  width: 100%;
}

.whoAreWeDescription {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 100px;
}

.whoAreWeDescription h2 {
  text-align: center;
  padding-bottom: 20px;
}



/* Detail Pages Mobile*/
@media screen and (max-width: 700px) {
  .whoAreWeDescription {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
  }
}












/* */
.patterns {
  height: 100vh;
  position: absolute;
  z-index: 4;
  width: 100%;
}



.patterns svg text {
  font-family: Lora;
  letter-spacing: 10px;
  stroke: #000000;
  font-size: 75px;
  font-weight: 700;
  stroke-width: 2;
  animation: textAnimate 3s infinite alternate;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: hsl(196, 100%, 35%)
  }

  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill: hsla(0, 0%, 100%, 0)
  }

}





/* WORKS PAGE */

.worksPageİmages img {
  width: 60%;
}

.worksPageİmages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.worksPage .worksPageDescription {
  padding-right: 150px;
  text-align: right;
}

.worksPage h2{
  color: white;
  font-size: 75px;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  text-align: -webkit-right;
  margin-top: 0px;

}

.worksPage .worksPageDescriptions h2{
  color: white;
  font-size: 75px;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  text-align: -webkit-left;
  margin-top: 0px;
}

.worksPage .worksPageDescriptions {
  padding-left: 150px;
}

.worksPage .ant-row {
  margin-bottom: 100px;
  margin-top: 100px;
}

.worksPage .reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1200px) {
 
  .worksPage .worksPageDescriptions h2{
    font-size: 50px;
  }
  .worksPage h2{
    font-size: 50px;
  }
  .worksPage .worksPageDescription {
    padding-right: 60px;
  }
  .worksPage .worksPageDescriptions {
    padding-left: 60px;
  }
}


@media screen and (max-width: 700px) {
  .worksPage .ant-row {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .worksPage .worksPageDescription {
    padding: 0px 20px;
    text-align: center;
  }

  .worksPage .worksPageDescriptions {
    padding: 0px 20px;
    text-align: center;
  }

  .worksPage h2{
    font-size: 30px;
    text-align: center;
  }
  
  .worksPage .worksPageDescriptions h2{
    font-size: 30px;
    text-align: center;
  }
  .worksPage .worksPageDescriptions {

  }
  .worksPage .worksPageDescriptions h2{
    font-size: 40px;
  }
  .worksPage h2{
    font-size: 40px;
  }


}




/* Container */

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
 
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}