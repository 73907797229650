
.card {
    margin: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #F7F7F7;
    border-radius: 10px;
    min-height: 250px;
}

.cardIcon {
    width: 25%;
}

.cardIcon span{
    width: 70%;
}

.cardIcon span svg{
    width: 70%;
    height: 1%;
    color: #0082AF;
}

.cardDescription {
    color: #67686a;
    width: 75%;
}

.cardDescription h2{
    font-weight: 800;
    font-size: 50px;
    margin: 0;
    padding-bottom: 20px;
}

.cardDescription p{
    font-size: 20px;
    margin: 0;
}

.cardDescription div:first-child {
    padding: 25px 0;
}

.contactPagesLead {
    margin-top: 30px;
}

.contactPagesLead button{
    display: block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-family: sans-serif;
    text-decoration: none;
    color: #333;
    border: 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all .35s;
    cursor: pointer;
    background-color: transparent;
}

.contactPagesLead button a {
    text-decoration: none;
    color: black;
}




/* */



.description, .link {
  font-family: 'Amatic SC', cursive;
  text-align: center;
}

.description {
	font-size: 35px;
}

.btn {
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  background-color: #67686A;
  padding: 12px 42px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
}

.btn a {
  position: relative; 
  z-index: 1;
  text-decoration: none;
  color: white;
}

.btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #0082AF;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}






/* MOBİLE */


@media screen and (max-width: 700px) {

    .card {
    margin: 20px;
    display: block;
    padding: 10px;
    }

    .cardIcon {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardDescription {
        width: 100%;
    }

    .cardDescription h2 {
        font-size: 30px;
    }
    
    .cardDescription .button_container{
        display: flex;
        justify-content: center;
    }

}