.workPage {
    height: 300px;
    width: 100%;
    background: rgb(0,68,146);
    background: linear-gradient(180deg, rgba(0,68,146,1) 0%, rgba(0,130,175,1) 100%);
}

.workPage h2 {
    font-size: 50px;
    display: flex;
    align-items: center;
    height: 225px;
    margin-left: 100px;
    color: #2a2a2a;
    margin: 0px 0px 0px 100px;
}

.workPage h4 {
    color: #2A2A2A;
    margin-left: 100px;
}

.workPage h4:hover{
    color: #FFFFFF;
}

.worksCard {
    margin: 100px 0;
    display: flex;
}

.worksCardDescription {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.worksCardDescription h2 {
    font-size: 40px;
    color: #424242;
}

.worksCardDescription p {
    font-size: 18px;
    color: #67686a;
}

.worksCardDoing h3 {
    font-size: 24px;
    color: #424242;
}

.worksCardDoing li {
    font-size: 18px;
    color: #67686a;
    padding: 20px 0px;
}

.worksPageDescription .btn:after {
    content: "";
    position: absolute;
    left: -6px !important;
    top: 20px !important;
    height: 300px !important;
    width: 113% !important;
    background: #0082AF;
    transition: all .5s ease-in-out;
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.worksPageDescription .btn:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.worksCardDescriptionWidth {
    width: 80%;
}

.worksCard .worksCardİmages {
    width: 50%;
}

.worksCard .worksCardİmages img {
    width: 90%;
    float: right;
}

.worksCardDoing {
    display: flex;
    justify-content: center;
}

.worksCardDoing .worksCardDoingDescription {
    width: 50%;
    background-color: #F1F2F2;
    padding: 100px;
    border-radius: 30px;
}



@media screen and (max-width: 1200px) {
    .worksCardDoing .worksCardDoingDescription {
        width: 100%;
        padding: 50px;
    }
  }
  
  @media screen and (max-width: 700px) {
    .workPage h2 {
        font-size: 38px;
        margin: 0px 0px 0px 50px;
    }

    .workPage h4 {
        margin-left: 50px;
    }
    .worksCardDescription h2 {
        font-size: 25px;
    }

    .worksCardDoing .worksCardDoingDescription {
        width: 100%;
        padding: 30px;
    }
  }
  