/* CONTACT */

.contactArea input{
    margin-bottom: 30px;
    border: 0px;
    border-bottom: 1px solid;
    width: 100%;
  
  }
.contactArea {
  margin-top: 100px;
  text-align: center;
}

  .contactArea form {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .contactFormArea {
    margin-right: 35%;
    margin-left: 35%;
    width: 30%;
  }

  .contactArea input:focus{
    outline: none;
  }
  
  .contactArea textarea{
    border: 0px;
    border-bottom: 1px solid;
    width: 100%;
  }
  
  .contactButton {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .contactButton button {
    display: block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-family: sans-serif;
    text-decoration: none;
    color: #333;
    border: 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all .35s;
    cursor: pointer;
    background-color: transparent;
  }
  
  .contactArea a span{
    position: relative;
    z-index: 2;
  }
  
  .contactButton button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #0082AF;
    transition: all .5s;
  }
  
  .contactButton button:hover span{
    color: #fff;
    position: sticky;
    z-index: 2;
  }
  
  .contactButton button:hover:after{
    width: 100%;
  }
  
  @media screen and (max-width: 700px) {
    .contactFormArea{
      margin-right: 25%;
      margin-left: 25%;
      width: 50%;
    }
  }
 
  